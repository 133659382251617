import React from 'react';
import axios from 'axios';
import Group from '../Group';
import Members from '../Members';
import Project from '../Project';
import {notify} from 'react-notify-toast';
import PropTypes from 'prop-types';

const CancelToken = axios.CancelToken;

export default class Access extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            running: false,
            allSubgroups: [], subgroupsWithMembers: [],
            allProjects: [], projectsWithMembers: []
        };

        this.ajaxStack = 0;
        this.axiosCancelFunctions = [];
        this.urlTypeToGO = 'members';
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.cancelReport);
        window.addEventListener('turbolinks:visit', this.cancelReport);
    }

    componentWillUnmount() {
        this.cancelReport();
    }

    cancelXhrRequests = () => {
        for (const cancelFunction of this.axiosCancelFunctions) {
            cancelFunction();
        }

        this.ajaxStack = 0; // Reset the stack after canceling
        this.axiosCancelFunctions = []; // Clear the cancel functions after canceling
    };

    runReport = () => {
        this.setState({
            running: true,
            allSubgroups: [], subgroupsWithMembers: [],
            allProjects: [], projectsWithMembers: []
        }, () => this.fetchGroup(this.props.group));
    };

    cancelReport = () => {
        this.setState({running: false}, this.cancelXhrRequests);
    };

    decrementAjaxStack = () => {
        if (this.ajaxStack > 0) {
            this.ajaxStack--;
        }

        if (this.ajaxStack === 0) {
            this.setState({running: false});
        }
    };

    fetchGroup(id) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${id}`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(response => {
                this.setState({allSubgroups: [response.data]});
                this.fetchSubgroups(response.data.id);
                this.fetchProjects(response.data.id);
                this.fetchGroupMembers(response.data);
            }).catch(error => {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }
            }).finally(this.decrementAjaxStack);
        }
    }

    fetchSubgroups(groupId) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${groupId}/subgroups`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(async response => {
                this.updateGroups(response.data);

                for (const group of response.data) {
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchSubgroups(group.id);
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchProjects(group.id);
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchGroupMembers(group);
                }
            })
                .catch(error => {
                    if (this.state.running) {
                        notify.show(error.message, 'error');
                    }
                })
                .finally(this.decrementAjaxStack);
        }
    }

    fetchProjects(groupId) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${groupId}/projects`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(async response => {
                this.updateProjects(response.data);

                for (const project of response.data) {
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchProjectMembers(project);
                }
            })
                .catch(error => {
                    if (this.state.running) {
                        notify.show(error.message, 'error');
                    }
                })
                .finally(this.decrementAjaxStack);
        }
    }

    fetchGroupMembers(group) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${group.id}/members`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(response => {
                group.members = response.data;
                this.setState({allSubgroups: this.state.allSubgroups});
                this.updateShownSubgroups(group);
            })
                .catch(error => {
                    if (this.state.running) {
                        notify.show(error.message, 'error');
                    }
                })
                .finally(this.decrementAjaxStack);
        }
    }

    fetchProjectMembers(project) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/projects/${project.id}/members`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(response => {
                project.members = response.data;
                this.setState({allProjects: this.state.allProjects});
                this.updateShownProjects(project);
            })
                .catch(error => {
                    if (this.state.running) {
                        notify.show(error.message, 'error');
                    }
                })
                .finally(this.decrementAjaxStack);
        }
    }

    updateGroups(newGroups) {
        if (this.state.running) {
            const sorted = newGroups.concat(this.state.allSubgroups || []).sort((a, b) => a.name.localeCompare(b.name));
            this.setState({allSubgroups: sorted});
        }
    }

    updateShownSubgroups(group) {
        if (this.state.running && group.members && group.members.length > 0) {
            this.state.subgroupsWithMembers.push(group);
            const sorted = this.state.subgroupsWithMembers.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({subgroupsWithMembers: sorted});
        }
    }

    updateProjects(newProjects) {
        if (this.state.running) {
            const sorted = newProjects.concat(this.state.allProjects || []).sort((a, b) =>
                a.name.localeCompare(b.name));
            this.setState({allProjects: sorted});
        }
    }

    updateShownProjects(project) {
        if (this.state.running && project.members && project.members.length > 0) {
            this.state.projectsWithMembers.push(project);
            const sorted = this.state.projectsWithMembers.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({projectsWithMembers: sorted});
        }
    }

    render() {
        return (
            <>
                {this.state.running > 0 && <div className='primary callout'>
                    <div className='grid-x grid-margin-x align-middle'>
                        <div className='shrink cell'>
                            <i className='fa fa-spinner fa-pulse'/> Running report...
                        </div>
                        <div className='auto cell'>
                            <button
                                className='button no-margin alert'
                                onClick={this.cancelReport}
                                type='button'>
                              Cancel
                            </button>
                        </div>
                        <div className='shrink cell'>
              API call stack: {this.ajaxStack}
                        </div>
                    </div>
                </div>}
                {!this.state.running && <div className='text-center'>
                    <a className='primary button' onClick={this.runReport}>Run Report</a>
                </div>}
                {(this.state.allSubgroups.length > 0 || this.state.allProjects.length > 0) &&
          <div className='grid-x grid-margin-x small-up-1 medium-up-2'>
              <div className='cell'>
                  <span className='lead'>
                            Subgroups ({this.state.subgroupsWithMembers.length} of {this.state.allSubgroups.length})
                  </span>
                  <hr/>
                  {!this.state.ajaxStack && !this.state.subgroupsWithMembers.length &&
                <div className='primary callout'>
                  There are no groups with special permissions.
                </div>}
                  {this.state.subgroupsWithMembers.map(group => <div className='tight' key={group.id}>
                      <Group
                          group={group}
                          key={group.id}
                          token={this.props.token}
                          urlTypeToGo={this.urlTypeToGO}
                      />
                      <Members collection={group.members} token={this.props.token}/>
                  </div>)}
              </div>
              <div className='cell'>
                  <span className='lead'>
                            Projects ({this.state.projectsWithMembers.length} of {this.state.allProjects.length})
                  </span>
                  <hr/>
                  {!this.state.ajaxStack && !this.state.projectsWithMembers.length &&
                <div className='primary callout'>
                  There are no projects with special permissions.
                </div>}
                  {this.state.projectsWithMembers.map(project => <div className='tight' key={project.id}>
                      <Project
                          key={project.id}
                          project={project}
                          token={this.props.token}
                          urlTypeToGo={this.urlTypeToGO}
                      />
                      <Members collection={project.members} token={this.props.token}/>
                  </div>)}
              </div>
          </div>}
            </>
        );
    }
}

Access.propTypes = {
    group: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired
};