import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Project from '../Project';
import {notify} from 'react-notify-toast';
import PropTypes from 'prop-types';
import {GitlabMergeRules, ProtectedBranchRules} from '../GitlabMergeRules';

const CancelToken = axios.CancelToken;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const ONE_WEEK_AGO = moment().subtract(1, 'week');

export default class RepoActivity extends React.Component {
    static checkMergeRules(project) {
        const errors = [];

        GitlabMergeRules.forEach(rule => {
            if (project[rule.property] !== rule.expected) {
                errors.push(rule.errorMessage);
            }
        });

        return errors;
    }

    constructor(props) {
        super(props);
        this.state = {
            running: false,
            allSubgroups: [],
            allProjects: []
        };

        this.ajaxStack = 0;
        this.axiosCancelFunctions = [];
        this.urlTypeToGO = 'members';
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.cancelReport);
        window.addEventListener('turbolinks:visit', this.cancelReport);
    }

    componentWillUnmount() {
        this.cancelReport();
    }

    cancelXhrRequests = () => {
        for (const cancelFunction of this.axiosCancelFunctions) {
            cancelFunction();
        }

        this.ajaxStack = 0; // Reset the stack after canceling
        this.axiosCancelFunctions = []; // Clear the cancel functions after canceling
    };

    runReport = () => {
        this.setState({
            running: true,
            allSubgroups: [],
            allProjects: []
        }, () => this.fetchGroup(this.props.group));
    };

    cancelReport = () => {
        this.setState({running: false}, this.cancelXhrRequests);
    };

    decrementAjaxStack = () => {
        if (this.ajaxStack > 0) {
            this.ajaxStack--;
        }

        if (this.ajaxStack === 0) {
            this.setState({running: false});
        }
    };

    fetchGroup(id) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${id}`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(response => {
                this.setState({allSubgroups: [response.data]});
                this.fetchSubgroups(response.data.id);
                this.fetchProjects(response.data.id);
            }).catch(error => {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }
            }).finally(this.decrementAjaxStack);
        }
    }

    fetchSubgroups(groupId) {
        if (this.state.running) {
            this.ajaxStack++;
            axios.get(`/api/gitlab/groups/${groupId}/subgroups`, {
                cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
            }).then(async response => {
                this.updateGroups(response.data);

                for (const group of response.data) {
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchSubgroups(group.id);
                    await new Promise(res => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
                    this.fetchProjects(group.id);
                }
            })
                .catch(error => {
                    if (this.state.running) {
                        notify.show(error.message, 'error');
                    }
                })
                .finally(this.decrementAjaxStack);
        }
    }

    async fetchProjects(groupId) {
        if (this.state.running) {
            this.ajaxStack++;

            try {
                const response = await axios.get(`/api/gitlab/groups/${groupId}/projects`, {
                    cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
                });

                const projects = [];

                for (const project of response.data) {
                    const projectData = await this.fetchProjectData(project); // eslint-disable-line no-await-in-loop
                    projects.push(projectData);

                    if (this.state.running) {
                        this.setState(state => {
                        // Convert allProjects array to a Map for efficient updates
                            const projectsMap = new Map(state.allProjects.map(p => [p.id, p]));

                            // Add new projects to the Map
                            projects.forEach(p => {
                                projectsMap.set(p.id, p);
                            });

                            // Convert the Map back to an array and sort by latestChangeDate
                            const allProjects = Array.from(projectsMap.values()).sort((a, b) =>
                                b.latestChangeDate - a.latestChangeDate);

                            return {allProjects};
                        });
                    }

                    await delay(300); // eslint-disable-line no-await-in-loop
                }
            }
            catch (error) {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }
            }
            finally {
                this.decrementAjaxStack();
            }
        }
    }

    async fetchProjectData(project) {
        const branchInfo = await this.fetchProjectBranches(project);
        const contributors = await this.fetchProjectContributors(project);
        const protectedBranch = await this.fetchProtectedBranch(project);
        const mergeRules = RepoActivity.checkMergeRules(project);
        const protectedBranchRules = this.checkProtectedBranchRules(protectedBranch);

        return {
            ...project,
            ...branchInfo,
            contributors,
            mergeRules,
            protectedBranchRules
        };
    }

    async fetchProjectBranches(project) {
        if (this.state.running) {
            this.ajaxStack++;

            try {
                const response = await axios.get(`/api/gitlab/projects/${project.id}/branches`, {
                    cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
                });

                return {
                    branches: response.data,
                    latestChangeDate: this.getRecentBranch(response.data).date,
                    mostRecentBranch: this.getRecentBranch(response.data).name
                };
            }
            catch (error) {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }

                return {};
            }
            finally {
                this.decrementAjaxStack();
            }
        }

        return {};
    }

    async fetchProtectedBranch(project) {
        if (this.state.running) {
            this.ajaxStack++;

            try {
                const response = await axios.get(`/api/gitlab/projects/${project.id}/protected_branches`, {
                    cancelToken: new CancelToken(token => this.axiosCancelFunctions.push(token))
                });

                const defaultBranch = response.data.find(p => p.name === 'master' || p.name === 'main');
                return defaultBranch;
            }
            catch (error) {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }

                return {};
            }
            finally {
                this.decrementAjaxStack();
            }
        }

        return {};
    }

    async fetchProjectContributors(project) {
        if (this.state.running) {
            this.ajaxStack++;

            try {
                const response = await axios.get(`/api/gitlab/projects/${project.id}/contributors`, {
                    cancelToken: new axios.CancelToken(token => this.axiosCancelFunctions.push(token))
                });

                return response.data.sort((a, b) => b.commits - a.commits);
            }
            catch (error) {
                if (this.state.running) {
                    notify.show(error.message, 'error');
                }

                return [];
            }
            finally {
                this.decrementAjaxStack();
            }
        }

        return [];
    }

    updateGroups(newGroups) {
        const sorted = newGroups.concat(this.state.allSubgroups || []).sort((a, b) => a.name.localeCompare(b.name));
        this.setState({allSubgroups: sorted});
    }

    // eslint-disable-next-line class-methods-use-this
    getRecentBranch(branches) {
        return branches.reduce((latest, branch) => {
            const branchDate = new Date(branch.commit.committed_date);

            if (branchDate > latest.date) {
                return {date: branchDate, name: branch.name};
            }

            return latest;
        }, {date: new Date(0), name: null});
    }

    // eslint-disable-next-line class-methods-use-this
    getInitials(name) {
        const nameArray = name.split(' ');

        if (nameArray.length > 1) {
            return `${nameArray[0].charAt(0) + nameArray[1].charAt(0)}`;
        }

        return `${nameArray[0].charAt(0)}`;
    }

    // eslint-disable-next-line class-methods-use-this
    getNestedProperty(obj, path) {
        // eslint-disable-next-line no-undefined
        return path.split('.').reduce((o, p) => o ? o[p] : undefined, obj);
    }

    checkProtectedBranchRules(branch) {
        const errors = [];

        ProtectedBranchRules.forEach(rule => {
            const value = this.getNestedProperty(branch, rule.property);

            if (value !== rule.expected) {
                errors.push(rule.errorMessage);
            }
        });

        return errors;
    }

    renderContributor = contributor =>
        <span
            className='empty-thumb empty-thumb-sec spaced right bottom'
            key={contributor.email}
            style={{cursor: 'default'}}
            title={`${contributor.name}\n<${contributor.email}>`}>
            {this.getInitials(contributor.name)}
        </span>;

    renderProject = project => {
        const defaultBranchIsBehind = project.mostRecentBranch !== project.default_branch &&
          moment(project.latestChangeDate).isBefore(ONE_WEEK_AGO);
        const hasInvalidMergeRules = project.mergeRules.length > 0;
        const hasInvalidProtectedBranchRules = project.protectedBranchRules.length > 0;

        return (
            <React.Fragment key={project.id}>
                <div className='grid-x grid-margin-x align-middle margin-vertical-1'>
                    <div className='medium-auto cell show-for-medium align-middle'>
                        <div className='lead'>
                            {moment(project.latestChangeDate).fromNow()}
                        </div>
                        <span className='subheader'>
                            {moment(project.latestChangeDate).format('lll')}
                        </span>
                    </div>
                    <div className='medium-9 cell'>
                        <Project
                            key={project.id}
                            project={project}
                            token={this.props.token}
                            urlTypeToGo={this.urlTypeToGO}
                        />
                        <div className='grid-x align-middle'>
                            <div className='shrink cell spaced right bottom'>
                                <span className='lead' title={`${project.branches?.length || 0} branches`}>
                                    <i className='fa fa-code-fork fa-fw'/>
                                    {project.branches?.length || 0}
                                </span>
                            </div>
                            <div className='auto cell'>
                                {project.contributors.map(this.renderContributor)}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {(defaultBranchIsBehind || hasInvalidMergeRules || hasInvalidProtectedBranchRules) &&
                    <div className='callout alert medium'>
                        <ul>
                            {defaultBranchIsBehind && <li>
                                {`<${project.default_branch}> branch is behind 
                                    <${project.mostRecentBranch}> (${moment(project.latestChangeDate).fromNow()})`}
                            </li>}
                            {hasInvalidMergeRules &&
                            // eslint-disable-next-line react/no-array-index-key
                                project.mergeRules.map((error, index) => <li key={index}>{error}</li>)
                            }
                            {hasInvalidProtectedBranchRules &&
                                // eslint-disable-next-line react/no-array-index-key
                                project.protectedBranchRules.map((error, index) => <li key={index}>{error}</li>)
                            }
                        </ul>
                    </div>
                    }
                </div>

                <hr/>
            </React.Fragment>
        );
    };

    render() {
        return (
            <>
                {this.state.running > 0 && <div className='primary callout'>
                    <div className='grid-x grid-margin-x align-middle'>
                        <div className='shrink cell'>
                            <i className='fa fa-spinner fa-pulse'/> Running report...
                        </div>
                        <div className='auto cell'>
                            <button
                                className='button no-margin alert'
                                onClick={this.cancelReport}
                                type='button'>
                                Cancel
                            </button>
                        </div>
                        <div className='shrink cell'>
                            API call stack: {this.ajaxStack}
                        </div>
                    </div>
                </div>}
                {!this.state.running && <div className='text-center'>
                    <a className='primary button' onClick={this.runReport}>Run Report</a>
                </div>}
                {this.state.allProjects.length > 0 && <>
                    <span className='lead'>
                        Projects ({this.state.allProjects.length})
                    </span>
                    <hr/>
                    {this.state.allProjects.map(this.renderProject)}
                </>}

            </>
        );
    }
}

RepoActivity.propTypes = {
    group: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired
};